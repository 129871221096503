import { Container, Box, Grid, Typography } from "@mui/material";
import styled from "@mui/system/styled";
import Image from "../assets/images/background.jpg";
import OmegaLogo from "../assets/images/omegalogo.png";
import DiscordImage from "../assets/images/discord.png";
import FacebookImage from "../assets/images/facebook.png";
import Button from "@mui/material/Button";
import DecorImage from "../assets/images/decor.png";
import React from "react";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import "../assets/app.css";
import Countdown from "react-countdown";

export const Home = () => {
  const Item = styled("div")(({ theme }) => ({
    backgroundColor: "transparent",
    padding: theme.spacing(3),
    borderRadius: "4px",
    textAlign: "center",
  }));

  const targetDate = new Date("November 23, 2024 21:00 GMT+2").getTime();
  const startDate = new Date("November 20, 2024").getTime();
  const distance = targetDate - startDate;

  const Completionist = () => (
    <React.Fragment>
      <Grid item={true} xs={12} sx={{ display: { xs: "none", xl: "block" } }}>
        <Item>
          <img
            alt="Online Icon"
            src={DecorImage}
            style={{ marginBottom: -30, padding: "1rem 1rem" }}
          />
          <MainButtonStyle
            onClick={handleOpen}
            style={{
              background: "#ffb221",
              color: "#fff",
              padding: "1rem 3rem",
              width: 400,
              height: 36,
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 25,
              border: "none",
              borderRadius: "2rem",
              transitionDuration: "0.4s",
            }}
          >
            Join Now
          </MainButtonStyle>
          <img
            alt="Online Icon"
            src={DecorImage}
            style={{
              marginBottom: -30,
              padding: "1rem 1rem",
              transform: "rotate(180deg)",
            }}
          />
        </Item>
      </Grid>
      <Grid
        item={true}
        xs={12}
        sx={{
          display: { xs: "block", md: "block", lg: "block", xl: "none" },
        }}
      >
        <Item>
          <MainButtonStyle
            onClick={handleOpen}
            style={{
              background: "#ffb221",
              color: "#fff",
              width: 350,
              height: 36,
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 25,
              border: "none",
              borderRadius: "2rem",
              transitionDuration: "0.4s",
            }}
          >
            Join Now 
          </MainButtonStyle>
        </Item>
      </Grid>
    </React.Fragment>
  );
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <React.Fragment>
          <Grid
            item={true}
            xs={12}
            sx={{ display: { xs: "none", xl: "block" } }}
          >
            <Item>
              <Typography
                sx={{
                  fontFamily: "Roboto Condensed Light  sans-serif",
                  fontWeight: "700",
                  fontSize: 60,
                  color: "#ffb221",
                  textDecoration: "none",
                  letterSpacing: "6px",
                  textShadow: "3px 3px 3px black",
                }}
              >
                Beta Opening
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto Condensed Light  sans-serif",
                  fontWeight: "700",
                  fontSize: 45,
                  color: "#ffb221",
                  textDecoration: "none",
                  letterSpacing: "6px",
                  textShadow: "3px 3px 3px black",
                }}
              >
                {days}d:{hours}h:{minutes}m:{seconds}s
              </Typography>
            </Item>
          </Grid>
          <Grid
            item={true}
            xs={12}
            sx={{
              display: { xs: "block", md: "block", lg: "block", xl: "none" },
            }}
          >
            <Item>
            <Typography
                sx={{
                  fontFamily: "Roboto Condensed Light  sans-serif",
                  fontWeight: "700",
                  fontSize: 45,
                  color: "#ffb221",
                  textDecoration: "none",
                  letterSpacing: "6px",
                  textShadow: "3px 3px 3px black",
                }}
              >
                Beta Opening
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto Condensed Light  sans-serif",
                  fontWeight: "700",
                  fontSize: 45,
                  color: "#ffb221",
                  textDecoration: "none",
                  letterSpacing: "6px",
                  textShadow: "3px 3px 3px black",
                }}
              >
                {days}d:{hours}h:{minutes}m:{seconds}s
              </Typography>
            </Item>
          </Grid>
        </React.Fragment>
      );
    }
  };

  const styles = {
    boxBackground: {
      backgroundImage: `url(${Image})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
  };

  const MainButtonStyle = styled(Button)({
    "&:hover": {
      background: "#5e3d2c",
      boxShadow: "0px 2px 20px 10px #5e3d2c",
    },
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    height: 600,
    bgcolor: "#1e1a23",

    borderRadius: "25px",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Container disableGutters maxWidth="true">
      <div className="socials">
        <div className="socials__item">
          <a
            href="https://www.facebook.com/Lineage2OmegaWorld"
            rel="noreferrer"
            className="circle-button orange flex-cc"
            target="_blank"
          >
            <span>
              <img src={FacebookImage} />
            </span>
          </a>
        </div>
        <div className="socials__item">
          <a
            href="https://discord.com/invite/fxupZCEJJz"
            rel="noreferrer"
            className="circle-button orange flex-cc"
            target="_blank"
          >
            <span>
              <img src={DiscordImage} />
            </span>
          </a>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Item>
                    <Typography
                      sx={{
                        fontFamily: "Roboto Condensed Light  sans-serif",
                        fontWeight: "700",
                        letterSpacing: "4px",
                        fontSize: 30,
                        color: "white",
                        textDecoration: "none",
                        textShadow: "3px 3px 3px black",
                      }}
                    >
                      How to Connect
                    </Typography>
                  </Item>
                  <Divider
                    orientation="horizontal"
                    flexItem
                    style={{
                      backgroundColor: "#ffd850",
                      height: "1px",
                      marginTop: 10,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Item>
                    {" "}
                    <Typography
                      sx={{
                        fontFamily: "Roboto Condensed Light  sans-serif",
                        fontWeight: "700",
                        letterSpacing: "4px",
                        fontSize: 20,
                        color: "#ffd850",
                        textDecoration: "none",
                      }}
                    >
                      1. Register Account
                    </Typography>
                    <Typography
                        sx={{
                          fontFamily: "Roboto Condensed Light  sans-serif",
                          fontWeight: "700",
                          letterSpacing: "4px",
                          fontSize: 15,
                          color: "#fff",
                          textDecoration: "none",
                        }}
                      >
                        First register for panel and after for game!
                      </Typography>
                    <MainButtonStyle
                      href="https://acp.l2omegaworld.com/register"
                      target="_blank"
                      style={{
                        background: "#ffb221",
                        color: "#fff",
                        padding: "1rem 3rem",
                        width: 200,
                        height: 36,
                        fontFamily: "Roboto Condensed Light  sans-serif",
                        fontWeight: "700",
                        letterSpacing: "4px",
                        fontSize: 10,
                        border: "none",
                        borderRadius: "2rem",
                        transitionDuration: "0.4s",
                        marginTop: 30,
                      }}
                    >
                      Register
                    </MainButtonStyle>
                  </Item>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Item>
                    {" "}
                    <Typography
                      sx={{
                        fontFamily: "Roboto Condensed Light  sans-serif",
                        fontWeight: "700",
                        letterSpacing: "4px",
                        fontSize: 20,
                        color: "#ffd850",
                        textDecoration: "none",
                      }}
                    >
                      2. Download Game
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Roboto Condensed Light  sans-serif",
                        fontWeight: "700",
                        letterSpacing: "2px",
                        fontSize: 20,
                        color: "white",
                        textDecoration: "none",
                        marginTop: 3,
                      }}
                    >
                      Download
                      <MainButtonStyle
                        href="https://www.ausgamers.com/files/download/59001/lineage-2-the-chaotic-throne--interlude-client"
                        target="_blank"
                        style={{
                          background: "#ffb221",
                          color: "#fff",
                          padding: "1rem 3rem",
                          width: 50,
                          height: 36,
                          fontFamily: "Roboto Condensed Light  sans-serif",
                          fontWeight: "700",
                          fontSize: 10,
                          border: "none",
                          borderRadius: "2rem",
                          transitionDuration: "0.4s",
                          marginTop: 2,
                          marginLeft: 10,
                        }}
                      >
                        Download
                      </MainButtonStyle>
                    </Typography>
                  </Item>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Item>
                    {" "}
                    <Typography
                      sx={{
                        fontFamily: "Roboto Condensed Light  sans-serif",
                        fontWeight: "700",
                        letterSpacing: "4px",
                        fontSize: 20,
                        color: "#ffd850",
                        textDecoration: "none",
                      }}
                    >
                      3. Download Patch
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Roboto Condensed Light  sans-serif",
                        fontWeight: "700",
                        letterSpacing: "2px",
                        fontSize: 20,
                        color: "white",
                        textDecoration: "none",
                        marginTop: 3,
                      }}
                    >
                      Mega Share
                      <MainButtonStyle
                        href="https://mega.nz/file/p1xREQTA#T2psNvV1UbKgA3r9xqDyGx17v4Kss5SzixV88Eu2LWc"
                        target="_blank"
                        style={{
                          background: "#ffb221",
                          color: "#fff",
                          padding: "1rem 3rem",
                          width: 50,
                          height: 36,
                          fontFamily: "Roboto Condensed Light  sans-serif",
                          fontWeight: "700",
                          fontSize: 10,
                          border: "none",
                          borderRadius: "2rem",
                          transitionDuration: "0.4s",
                          marginTop: 2,
                          marginLeft: 10,
                        }}
                      >
                        Download
                      </MainButtonStyle>
                    </Typography>
                  </Item>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Item>
                      {" "}
                      <Typography
                        sx={{
                          fontFamily: "Roboto Condensed Light  sans-serif",
                          fontWeight: "700",
                          letterSpacing: "4px",
                          fontSize: 20,
                          color: "#ffd850",
                          textDecoration: "none",
                        }}
                      >
                        3. Download Updater
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Roboto Condensed Light  sans-serif",
                          fontWeight: "700",
                          letterSpacing: "4px",
                          fontSize: 15,
                          color: "#fff",
                          textDecoration: "none",
                        }}
                      >
                        Put the updater on C:\LINEAGE
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Roboto Condensed Light  sans-serif",
                          fontWeight: "700",
                          letterSpacing: "2px",
                          fontSize: 20,
                          color: "white",
                          textDecoration: "none",
                          marginTop: 3,
                        }}
                      >
                        Mega Share
                        <MainButtonStyle
                          href="https://mega.nz/file/hpRWCBCY#-cqjp22z0qxheddDzGgN02E140b_-_L-lG2jtYxjWUA"
                          target="_blank"
                          style={{
                            background: "#ffb221",
                            color: "#fff",
                            padding: "1rem 3rem",
                            width: 50,
                            height: 36,
                            fontFamily: "Roboto Condensed Light  sans-serif",
                            fontWeight: "700",
                            fontSize: 10,
                            border: "none",
                            borderRadius: "2rem",
                            transitionDuration: "0.4s",
                            marginTop: 2,
                            marginLeft: 10,
                          }}
                        >
                          Download
                        </MainButtonStyle>
                      </Typography>
                    </Item>
                  </Grid>
              </Grid>
            </Box>
          </Typography>
        </Box>
      </Modal>
      <Box
        display="flex"
        justifyContent="center"
        height="75vh"
        sx={{ overflow: "hidden" }}
      >
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item={true} xs={12}>
              <Item>
                <img
                  alt="Online Icon"
                  src={OmegaLogo}
                  style={{ maxWidth: "700px", minWidth: "400px", width: "45%" }}
                />
              </Item>
            </Grid>
            <Grid item={true} xs={12}>
              <Item>
                {" "}
                <Typography
                  sx={{
                    fontFamily: "Roboto Condensed Light  sans-serif",
                    fontWeight: "700",
                    letterSpacing: "4px",
                    fontSize: 30,
                    color: "white",
                    textDecoration: "none",
                    textShadow: "3px 3px 3px black",
                  }}
                >
                  Welcome to L2 Omega World
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto Condensed Light  sans-serif",
                    fontWeight: "700",
                    fontSize: 25,
                    color: "white",
                    textDecoration: "none",
                    textShadow: "3px 3px 3px black",
                  }}
                >
                  PvP Intelude Server
                </Typography>
              </Item>
            </Grid>

            <Countdown date={startDate + distance} renderer={renderer} />

          </Grid>
        </Box>
      </Box>
    </Container>
  );
};
export default Home;
