import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import logo from "../assets/images/logo.png";
import bottom from "../assets/images/bottom.png";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import "../assets/app.css";
import Features from "./Features";
import { Link } from 'react-router-dom';

function ResponsiveAppBar() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "transparent",
    padding: theme.spacing(2),
    textAlign: "center",
    boxShadow: "none",
    justifyContent: "space-between",
  }));

  const Item2 = styled(Paper)(({ theme }) => ({
    backgroundColor: "transparent",
    padding: theme.spacing(2),

    boxShadow: "none",
    justifyContent: "space-between",
  }));

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const ColorButton = styled(Button)({
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "15px",
      bottom: "-10px",
      left: "50%",
      transform: "translate(-50%,0%)",
      backgroundImage: `url(${bottom})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      visibility: "hidden",
      transition: "all 0.3s ease-in-out",
    },
    "&:hover:before": {
      visibility: "visible",
      width: "100%",
    },
  });

  const ColorButtonSide = styled(Button)({
    "&:hover": {
      backgroundColor: "#d8af1e",
    },
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    height: 600,
    bgcolor: "#1e1a23",

    borderRadius: "25px",
    boxShadow: 24,
    p: 4,
  };

  const styleFeatures = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    height: 600,
    bgcolor: "#1e1a23",
    overflowY: "scroll",
    borderRadius: "25px",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const MainButtonStyle = styled(Button)({
    "&:hover": {
      background: "#5e3d2c",
      boxShadow: "0px 2px 20px 10px #5e3d2c",
    },
  });

  return (
    <AppBar
      position="static"
      style={{ background: "transparent", boxShadow: "none" }}
    >
      <Container maxWidth="false">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Item>
                      <Typography
                        sx={{
                          fontFamily: "Roboto Condensed Light  sans-serif",
                          fontWeight: "700",
                          letterSpacing: "4px",
                          fontSize: 30,
                          color: "white",
                          textDecoration: "none",
                          textShadow: "3px 3px 3px black",
                        }}
                      >
                        How to Connect
                      </Typography>
                    </Item>
                    <Divider
                      orientation="horizontal"
                      flexItem
                      style={{
                        backgroundColor: "#ffd850",
                        height: "1px",
                        marginTop: 10,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Item>
                      {" "}
                      <Typography
                        sx={{
                          fontFamily: "Roboto Condensed Light  sans-serif",
                          fontWeight: "700",
                          letterSpacing: "4px",
                          fontSize: 20,
                          color: "#ffd850",
                          textDecoration: "none",
                        }}
                      >
                        1. Register Account
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Roboto Condensed Light  sans-serif",
                          fontWeight: "700",
                          letterSpacing: "4px",
                          fontSize: 15,
                          color: "#fff",
                          textDecoration: "none",
                        }}
                      >
                        First register for panel and after for game!
                      </Typography>
                      <MainButtonStyle
                        href="https://acp.l2omegaworld.com/register"
                        target="_blank"
                        style={{
                          background: "#ffb221",
                          color: "#fff",
                          padding: "1rem 3rem",
                          width: 200,
                          height: 36,
                          fontFamily: "Roboto Condensed Light  sans-serif",
                          fontWeight: "700",
                          letterSpacing: "4px",
                          fontSize: 10,
                          border: "none",
                          borderRadius: "2rem",
                          transitionDuration: "0.4s",
                          marginTop: 30,
                        }}
                      >
                        Register
                      </MainButtonStyle>
                    </Item>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Item>
                      {" "}
                      <Typography
                        sx={{
                          fontFamily: "Roboto Condensed Light  sans-serif",
                          fontWeight: "700",
                          letterSpacing: "4px",
                          fontSize: 20,
                          color: "#ffd850",
                          textDecoration: "none",
                        }}
                      >
                        2. Download Interlude Client
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Roboto Condensed Light  sans-serif",
                          fontWeight: "700",
                          letterSpacing: "2px",
                          fontSize: 20,
                          color: "white",
                          textDecoration: "none",
                          marginTop: 3,
                        }}
                      >
                        Download
                        <MainButtonStyle
                          href="https://www.ausgamers.com/files/download/59001/lineage-2-the-chaotic-throne--interlude-client"
                          target="_blank"
                          style={{
                            background: "#ffb221",
                            color: "#fff",
                            padding: "1rem 3rem",
                            width: 50,
                            height: 36,
                            fontFamily: "Roboto Condensed Light  sans-serif",
                            fontWeight: "700",
                            fontSize: 10,
                            border: "none",
                            borderRadius: "2rem",
                            transitionDuration: "0.4s",
                            marginTop: 2,
                            marginLeft: 10,
                          }}
                        >
                          Download
                        </MainButtonStyle>
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Item>
                      {" "}
                      <Typography
                        sx={{
                          fontFamily: "Roboto Condensed Light  sans-serif",
                          fontWeight: "700",
                          letterSpacing: "4px",
                          fontSize: 20,
                          color: "#ffd850",
                          textDecoration: "none",
                        }}
                      >
                        3. Download Patch
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Roboto Condensed Light  sans-serif",
                          fontWeight: "700",
                          letterSpacing: "2px",
                          fontSize: 20,
                          color: "white",
                          textDecoration: "none",
                          marginTop: 3,
                        }}
                      >
                        Mega Share
                        <MainButtonStyle
                          href="https://mega.nz/file/p1xREQTA#T2psNvV1UbKgA3r9xqDyGx17v4Kss5SzixV88Eu2LWc"
                          target="_blank"
                          style={{
                            background: "#ffb221",
                            color: "#fff",
                            padding: "1rem 3rem",
                            width: 50,
                            height: 36,
                            fontFamily: "Roboto Condensed Light  sans-serif",
                            fontWeight: "700",
                            fontSize: 10,
                            border: "none",
                            borderRadius: "2rem",
                            transitionDuration: "0.4s",
                            marginTop: 2,
                            marginLeft: 10,
                          }}
                        >
                          Download
                        </MainButtonStyle>
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Item>
                      {" "}
                      <Typography
                        sx={{
                          fontFamily: "Roboto Condensed Light  sans-serif",
                          fontWeight: "700",
                          letterSpacing: "4px",
                          fontSize: 20,
                          color: "#ffd850",
                          textDecoration: "none",
                        }}
                      >
                        3. Download Updater
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Roboto Condensed Light  sans-serif",
                          fontWeight: "700",
                          letterSpacing: "4px",
                          fontSize: 15,
                          color: "#fff",
                          textDecoration: "none",
                        }}
                      >
                        Put the updater on C:\LINEAGE
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Roboto Condensed Light  sans-serif",
                          fontWeight: "700",
                          letterSpacing: "2px",
                          fontSize: 20,
                          color: "white",
                          textDecoration: "none",
                          marginTop: 3,
                        }}
                      >
                        Mega Share
                        <MainButtonStyle
                          href="https://mega.nz/file/hpRWCBCY#-cqjp22z0qxheddDzGgN02E140b_-_L-lG2jtYxjWUA"
                          target="_blank"
                          style={{
                            background: "#ffb221",
                            color: "#fff",
                            padding: "1rem 3rem",
                            width: 50,
                            height: 36,
                            fontFamily: "Roboto Condensed Light  sans-serif",
                            fontWeight: "700",
                            fontSize: 10,
                            border: "none",
                            borderRadius: "2rem",
                            transitionDuration: "0.4s",
                            marginTop: 2,
                            marginLeft: 10,
                          }}
                        >
                          Download
                        </MainButtonStyle>
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </Typography>
          </Box>
        </Modal>
        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleFeatures}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Item>
                      <Typography
                        sx={{
                          fontFamily: "Roboto Condensed Light  sans-serif",
                          fontWeight: "700",
                          letterSpacing: "4px",
                          fontSize: 45,
                          color: "white",
                          textDecoration: "none",
                          textShadow: "3px 3px 3px black",
                        }}
                      >
                        Server Features
                      </Typography>
                    </Item>
                    <Divider
                      orientation="horizontal"
                      flexItem
                      style={{
                        backgroundColor: "#ffd850",
                        height: "1px",
                        marginTop: 10,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Item2>
                      <Features />
                    </Item2>
                  </Grid>
                </Grid>
              </Box>
            </Typography>
          </Box>
        </Modal>
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "none", md: "flex" }, mr: 10 }}>
            <img
              alt="Online Icon"
              src={logo}
              style={{ height: 60, width: 60 }}
            />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleOpen}>
                <Typography textAlign="center">Connect</Typography>
              </MenuItem>
              <MenuItem onClick={handleOpen2}>
                <Typography textAlign="center">Features</Typography>
              </MenuItem>
              <MenuItem>
                <Typography
                  component="a"
                  target="_blank"
                  href="https://acp.l2omegaworld.com/login"
                  textAlign="center"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Donate
                </Typography>
              </MenuItem>
              <MenuItem>
                <Typography
                  component="a"
                  target="_blank"
                  href="https://acp.l2omegaworld.com/login"
                  textAlign="center"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Login
                </Typography>
              </MenuItem>
              <MenuItem>
                <Typography
                  component="a"
                  target="_blank"
                  href="https://acp.l2omegaworld.com/register"
                  textAlign="center"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Register
                </Typography>
              </MenuItem>
              <MenuItem>
                <Typography
                  component="a"
                  target="_blank"
                  href="hhttps://www.facebook.com/Lineage2OmegaWorld"
                  textAlign="center"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Facebook
                </Typography>
              </MenuItem>
              <MenuItem>
                <Typography
                  component="a"
                  target="_blank"
                  href="https://discord.com/invite/fxupZCEJJz"
                  textAlign="center"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Discord
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
            <img
              alt="Online Icon"
              src={logo}
              style={{ height: 60, width: 60 }}
            />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to="/"
              key="main"
            >            <ColorButton
              disableRipple={true}
              sx={{
                m: 3,
                my: 2,
                color: "white",
                display: "block",
                fontFamily: "Roboto Condensed Light  sans-serif",
                fontWeight: "700",
                letterSpacing: "4px",
              }}
            >
                Main
              </ColorButton></Link>
            <ColorButton
              onClick={handleOpen}
              disableRipple={true}
              sx={{
                m: 3,
                my: 2,
                color: "white",
                display: "block",
                fontFamily: "Roboto Condensed Light  sans-serif",
                fontWeight: "700",
                letterSpacing: "4px",
              }}
            >
              Connect
            </ColorButton>
            <ColorButton
              disableRipple={true}
              onClick={handleOpen2}
              sx={{
                m: 3,
                my: 2,
                color: "white",
                display: "block",
                fontFamily: "Roboto Condensed Light  sans-serif",
                fontWeight: "700",
                letterSpacing: "4px",
              }}
            >
              Features
            </ColorButton>
            <ColorButton
              href="https://acp.l2omegaworld.com"
              onClick={handleCloseNavMenu}
              disableRipple={true}
              sx={{
                m: 3,
                my: 2,
                color: "white",
                display: "block",
                fontFamily: "Roboto Condensed Light  sans-serif",
                fontWeight: "700",
                letterSpacing: "4px",
              }}
            >
              Donate
            </ColorButton>
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Tooltip>
              <ColorButtonSide
                href="https://acp.l2omegaworld.com/login"
                sx={{
                  m: 3,
                  my: 2,
                  color: "white",
                  display: "block",
                  fontFamily: "Roboto Condensed Light  sans-serif",
                  fontWeight: "700",
                  letterSpacing: "4px",
                  fontSize: 20,
                }}
              >
                Login
              </ColorButtonSide>
            </Tooltip>
            <Divider
              orientation="vertical"
              flexItem
              style={{
                backgroundColor: "#ffd850",
                height: "3.4rem",
                marginTop: 10,
              }}
            />
            <Tooltip>
              <ColorButtonSide
                href="https://acp.l2omegaworld.com/register"
                sx={{
                  m: 3,
                  my: 2,
                  color: "white",
                  display: "block",
                  fontFamily: "Roboto Condensed Light  sans-serif",
                  fontWeight: "700",
                  letterSpacing: "4px",
                  fontSize: 20,
                }}
              >
                Register
              </ColorButtonSide>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
