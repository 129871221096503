import ResponsiveAppBar from "./componets/ResponsiveAppBar";
import Home from "./pages/Home";
import Footer from "./componets/Footer";
import Video from "./assets/videos/video.mp4";
import "./assets/app.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BlogList from "./Blog/BlogList";
import SingleBlog from "./Blog/SingleBlog";

function App() {
  return (
    <div id="myVideo">
      <video src={Video} autoPlay loop muted />
        <Router>
        <ResponsiveAppBar />
          <Routes>
          <Route path="/" element={<Home />} />
            <Route path="/news/:id" element={<SingleBlog />} />
            <Route path="/news" element={<BlogList />} />
          </Routes>
          <Footer />
        </Router>
    </div>
  );
}

export default App;
