import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { Box, Grid, Typography, Paper, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import "../assets/app.css";
import DiscordImage from "../assets/images/discord.png";
import FacebookImage from "../assets/images/facebook.png";

const BlogList = () => {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
    setLoading(true);
    const res = await fetch(`https://panel.l2omegaworld.com/api/posts?page=${page}`);
    const data = await res.json();
    setPosts([...posts, ...data.data]);
    setLoading(false);
  };

  const convertDate = (date) => {
    const newDate = new Date(date);
    const month = newDate.toLocaleString("default", { month: "long" });
    const day = newDate.getDate();
    const year = newDate.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  const link = "https://panel.l2omegaworld.com/assets/images/";

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(3),
    backgroundColor: "transparent",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  }));

  const Item2 = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(3),
    backgroundColor: "transparent",
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  }));

  const MainButtonStyle = styled(Button)({
    "&:hover": {
      background: "#5e3d2c",
      boxShadow: "0px 2px 5px 5px #5e3d2c",
    },
  });

  return (
    <Box
      minHeight="80vh"
      sx={{ mx: "auto", my: "auto", mt: -15, width: "80%", maxWidth: "1200px" }}
    >
      <div className="socials">
        <div className="socials__item">
          <a
            href="https://www.facebook.com/Lineage2OmegaWorld"
            rel="noreferrer"
            className="circle-button orange flex-cc"
            target="_blank"
          >
            <span>
              <img src={FacebookImage} />
            </span>
          </a>
        </div>
        <div className="socials__item">
          <a
            href="https://discord.gg/fxupZCEJJz"
            rel="noreferrer"
            className="circle-button orange flex-cc"
            target="_blank"
          >
            <span>
              <img src={DiscordImage} />
            </span>
          </a>
        </div>
      </div>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        columns={{ xs: 4, sm: 4, md: 8, lg: 12, xl: 12 }}
        spacing={2}
        sx={{ mx: "auto", my: "auto", mt: 10, gridGap: "4" }}
      >
        <Grid item xs={12} rowSpacing={5}>
          <Item style={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontFamily: "Roboto Condensed Light  sans-serif",
                fontWeight: "700",
                letterSpacing: "4px",
                fontSize: 60,
                color: "white",
                textDecoration: "none",
                textShadow: "3px 3px 3px black",
              }}
            >
              Server News
            </Typography>
          </Item>
        </Grid>
        {posts.map((post) => (
          <Grid item xs={6} rowSpacing={5} key={post.id}>
            <Item>
              <Card sx={{ width: "auto" }}>
                <CardMedia
                  key={post.id}
                  sx={{ height: 200 }}
                  image={link + post.image}
                  title={post.title}
                />
                <CardContent
                  sx={{ backgroundColor: "#1e1a23", color: "white" }}
                >
                  <Typography gutterBottom variant="h5" component="div">
                    {post.category}
                  </Typography>
                  <Typography gutterBottom variant="h5" component="div">
                    {post.title}
                  </Typography>
                  <Typography variant="body2">
                    {convertDate(post.created_at)}
                  </Typography>
                </CardContent>
                <CardActions sx={{ backgroundColor: "#1e1a23", height: 50 }}>
                  <Link
                    to={`/news/${post.id}`}
                    className="button button1"
                    style={{
                      color: "initial",
                      textDecoration: "none",
                    }}
                  >
                    <MainButtonStyle
                      style={{
                        background: "#ffb221",
                        color: "#fff",
                        width: 100,
                        height: 36,
                        fontFamily: "Roboto Condensed Light  sans-serif",
                        fontWeight: "700",
                        fontSize: 10,
                        border: "none",
                        borderRadius: "2rem",
                        transitionDuration: "0.4s",
                      }}
                    >
                      Read More
                    </MainButtonStyle>
                  </Link>
                </CardActions>
              </Card>
            </Item>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Item2>
            {loading ? (
              <CircularProgress sx={{ color: "#ffb221" }} />
            ) : (
              <MainButtonStyle
                onClick={() => setPage(page + 1)}
                style={{
                  background: "#ffb221",
                  color: "#fff",
                  width: 200,
                  height: 36,
                  fontFamily: "Roboto Condensed Light  sans-serif",
                  fontWeight: "700",
                  fontSize: 20,
                  border: "none",
                  borderRadius: "2rem",
                  transitionDuration: "0.4s",
                }}
                
              >
                Load More..
              </MainButtonStyle>
            )}
          </Item2>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BlogList;
