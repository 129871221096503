import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FeaturesImage from "../assets/images/features.png";
import "../assets/app.css";
import iconxp from "../assets/images/icons/xp.png";
import ItemsImage from "../assets/images/items.png";
import EnchantImage from "../assets/images/enchant.png";
import EventsImage from "../assets/images/events.png";

export const Features = () => {
  const generals = [
    "Interlude Pride Like Server",
    "Starting Level: 80",
    "Max Level: 95",
    "Subclass Return Level : 80",
    "Max Subclass: 6",
    "AutoFarm",
    "Battle pass",
    "Rebirth System",
    "Soul system",
    "Instances",

  ];
  const farms = [
    "Cave Of Trials: Easy",
    "Cemetery: Hard",
    "Party Zone: Hard opens every 4 hours and stays on 2 hours.",
  ];

  const customs = [
    "Custom NPC",
    "Custom Raid Bosses",
    "Custom Farm System",
    "Commiunity Board",
    "Agathions",
  ];

  const vips = [
    "Adena x2",
    "Drop x2 Farm Fragments",
  ];

  const buffs = [
    "Buff Slots: 44",
    "Buff Time: 3 hours",
    "Scheme Buff: Active",
    "All buffs in NPC or Alt+B",
  ];
  const commands = [
    ".withdraw / .deposit",
    "suggestdiscord  <text>  your suggestion will be send on discord suggestions channel ",
    '"AdminDiscord <text> you can pm server staff on discord',
    ".farm autofarm",
    ".rb and .leave to leave from rb",
  ];

  const items = [
    "Armors: (Tier 1: Titanium, Dread) (Tier 2: Dynasty) (Tier 3: Vesper)",
    "Weapons: (Tier 1: Unique) (Tier 2: Dynasty) (Tier 3: Vesper)",
    "Accessories: Up to 30 Accessories with unique stats.",
    "Legendary Dyes +5 -2 Status",
  ];

  const augmentations = [
    "Mid life stone skill chance - 5%.",
    "High life stone skill chance - 10%.",
    "Top life stone skill chance - 15%.",
    "Augmentation rate retail like 1+1.",
  ];

  const enchantMaxItems = [
    "Safe Enchant +7",
    "Max Enchant Weapon +25 ",
    "Max Enchant Armor +25",
  ];
  const enchantNormalWeapon = [
    "Min +7 • Max +25 " ,
    "+7 to +16 (70 % Chance) • Decress 5% per enchant",
  ];

  const enchantNormalArmor = [
    "Min +7 • Max +25" ,
    "+7 to +16 (60% Chance) • Decress 5% per enchant",
  ];

  const enchantBlessedWeapon = [
    "Min +7 • Max +25 │ Fail -- Return +7" ,
    "+7 to +16 (75 % Chance) • Decress 5% per enchant",
  ];

  const enchantBlessedArmor = [
    "Min +7 • Max +25 │ Fail -- Return +7" ,
    "+7 to +16 (65% Chance) • Decress 5% per enchant",
  ];

  const enchantCrystal = ["Crystal Enchant Armor and Weapon Chance 40% on every enchant | Fail -- Keeps Enchant"];

  const olympiads = [
    "Period: 7 Days",
    "Battle Time: 18:00 - 00:00 (GMT +1)",
    "Minimum fights to become HERO: 9 Fight",
    "Olympiad Item: A/S Grade(No Custom items Allowed)",
    "Max Enchant Status: +6",
  ];

  const customevents = [
    "DeathMatch",
    "King of the hill",
    "Team vs Team",
    "Mutant",
    "Capture the Flag",
    "Battle Royale",
    "Korean TvT",
    "Protect The Flag",
    "Party Farm Event",
  ];


  const styles = {
    serverGameplay: {
      backgroundImage: `url(${FeaturesImage})`,
      backgroundColor: "#1e1a23",
      color: "white",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    serverItems: {
        backgroundImage: `url(${ItemsImage})`,
        backgroundColor: "#1e1a23",
        color: "white",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      enchantInformation: {
        backgroundImage: `url(${EnchantImage})`,
        backgroundColor: "#1e1a23",
        color: "white",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      eventsInformation:{
        backgroundImage: `url(${EventsImage})`,
        backgroundColor: "#1e1a23",
        color: "white",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon fontSize="large" sx={{ color: "white" }} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={styles.serverGameplay}
        >
          <Typography
            align="center"
            sx={{
              width: "100%",
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              textShadow: "3px 3px 3px black",
              display: { xs: 'none', sm: 'block' }
            }}
          >
            Server Gameplay
          </Typography>
          <Typography
            align="center"
            sx={{
              width: "100%",
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              ml: 8,
              color: "#ffd850",
              textDecoration: "none",
              textShadow: "3px 3px 3px black",
              display: { xs: 'block', sm: 'none' }
            }}
          >
            Server Gameplay
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "#1e1a23",
            color: "white",
            justifyContent: "start",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'none', sm: 'block' }
            }}
          >
            General 
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              textAlign: "center",
              ml: 3,
              display: { xs: 'block', sm: 'none' }
            }}
          >
            General 
          </Typography>
          <ul className="listdesktop">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              generals.map((general) => (
                <li key={general}>{general}</li>
              ))
            }
          </ul>
          <ul className="listmobile">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              generals.map((general) => (
                <li key={general}>{general}</li>
              ))
            }
          </ul>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'none', sm: 'block' }
            }}
          >
            Farm 
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              textAlign: "center",
              ml: 5,
              display: { xs: 'block', sm: 'none' }
            }}
          >
            Farm 
          </Typography>
          <ul className="listdesktop">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              farms.map((farm) => (
                <li key={farm}>{farm}</li>
              ))
            }
          </ul>
          <ul className="listmobile">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              farms.map((farm) => (
                <li key={farm}>{farm}</li>
              ))
            }
          </ul>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'none', sm: 'block' }
            }}
          >
            Customs 
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              ml: 5,
              textAlign: "center",
              display: { xs: 'block', sm: 'none' }
            }}
          >
            Customs 
          </Typography>
          <ul className="listdesktop">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              customs.map((custom) => (
                <li key={custom}>{custom}</li>
              ))
            }
          </ul>
          <ul className="listmobile">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              customs.map((custom) => (
                <li key={custom}>{custom}</li>
              ))
            }
          </ul>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'none', sm: 'block' }
            }}
          >
            Vip System 
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              ml: 5,
              textDecoration: "none",
              textAlign: "center",
              display: { xs: 'block', sm: 'none' }  
            }}
          >
            Vip System 
          </Typography>
          <ul className="listdesktop">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              vips.map((vip) => (
                <li key={vip}>{vip}</li>
              ))
            }
          </ul>
          <ul className="listmobile">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              vips.map((vip) => (
                <li key={vip}>{vip}</li>
              ))
            }
          </ul>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'none', sm: 'block' }
            }}
          >
            Buffs and Buffer Information 
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              ml: 5,
              textAlign: "center",
              display: { xs: 'block', sm: 'none' }
            }}
          >
            Buffs Information 
          </Typography>
          <ul className="listdesktop">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              buffs.map((buff) => (
                <li key={buff}>{buff}</li>
              ))
            }
          </ul>
          <ul className="listmobile">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              buffs.map((buff) => (
                <li key={buff}>{buff}</li>
              ))
            }
          </ul>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'none', sm: 'block' }
            }}
          >
            Commands 
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              ml: 5,
              textAlign: "center",
              display: { xs: 'block', sm: 'none' }
            }}
          >
            Commands 
          </Typography>
          <ul className="listdesktop">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              commands.map((command) => (
                <li key={command}>{command}</li>
              ))
            }
          </ul>
          <ul className="listmobile">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              commands.map((command) => (
                <li key={command}>{command}</li>
              ))
            }
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon fontSize="large" sx={{ color: "white" }} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={styles.serverItems}
        >
          <Typography
            align="center"
            sx={{
              width: "100%",
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              textShadow: "3px 3px 3px black",
              display: { xs: 'none', sm: 'block' }
            }}
          >
            Server Items
          </Typography>
          <Typography
            align="center"
            sx={{
              width: "100%",
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              textShadow: "3px 3px 3px black",
              ml: 7,
              display: { xs: 'block', sm: 'none' }
            }}
          >
            Server Items
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "#1e1a23", color: "white" }}>
        <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'none', sm: 'block' }
            }}
          >
            Weapons | Armors | Jewels | Tatoo 
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              fontSize: 25,
              color: "#ffd850",
              ml: 2,
              textDecoration: "none",
              display: { xs: 'block', sm: 'none' },
              textAlign: "center",
            }}
          >
            Weapons | Armors | Jewels | Tatoo 
          </Typography>
          <ul className="listdesktop">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              items.map((item) => (
                <li key={item}>{item}</li>
              ))
            }
          </ul>
          <ul className="listmobile">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              items.map((item) => (
                <li key={item}>{item}</li>
              ))
            }
          </ul>

          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'none', sm: 'block' }
            }}
          >
            Augmentation Items 
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 25,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'block', sm: 'none' },
              textAlign: "center",
              ml: 4,
            }}
          >
            Augmentation Items 
          </Typography>
          <ul className="listdesktop">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              augmentations.map((Augmentation) => (
                <li key={Augmentation}>{Augmentation}</li>
              ))
            }
          </ul>
          <ul className="listmobile">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              augmentations.map((Augmentation) => (
                <li key={Augmentation}>{Augmentation}</li>
              ))
            }
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon fontSize="large" sx={{ color: "white" }} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={styles.enchantInformation}
        >
          <Typography
            align="center"
            sx={{
              width: "100%",
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              textShadow: "3px 3px 3px black",
              display: { xs: 'none', sm: 'block' }
            }}
          >
            Enchant Information 
          </Typography>
          <Typography
            align="center"
            sx={{
              width: "100%",
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              textShadow: "3px 3px 3px black",
              ml: 7,
              display: { xs: 'block', sm: 'none' }
            }}
          >
            Enchant Information 
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "#1e1a23", color: "white" }}>
        <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'none', sm: 'block' }
            }}
          >
            Max Enchant 
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 25,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'block', sm: 'none' },
              textAlign: "center",
              ml: 4,
            }}
          >
            Max Enchant 
          </Typography>
          <ul className="listdesktop">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              enchantMaxItems.map((enchantMaxItem) => (
                <li key={enchantMaxItem}>{enchantMaxItem}</li>
              ))
            }
          </ul>
          <ul className="listmobile">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              enchantMaxItems.map((enchantMaxItem) => (
                <li key={enchantMaxItem}>{enchantMaxItem}</li>
              ))
            }
          </ul>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'none', sm: 'block' }
            }}
          >
            Normal Enchant Scroll Weapon 
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              fontSize: 25,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'block', sm: 'none' },
              textAlign: "center",
              ml: 4,
            }}
          >
            Normal Enchant Scroll Weapon 
          </Typography>
          <ul className="listdesktop">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              enchantNormalWeapon.map((enchantNormalWeapons) => (
                <li key={enchantNormalWeapons}>{enchantNormalWeapons}</li>
              ))
            }
          </ul>
          <ul className="listmobile">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              enchantNormalWeapon.map((enchantNormalWeapons) => (
                <li key={enchantNormalWeapons}>{enchantNormalWeapons}</li>
              ))
            }
          </ul>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'none', sm: 'block' }
            }}
          >
            Normal Enchant Scroll Armor 
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              fontSize: 25,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'block', sm: 'none' },
              textAlign: "center",
              ml: 4,
            }}
          >
            Normal Enchant Scroll Armor 
          </Typography>
          <ul className="listdesktop">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              enchantNormalArmor.map((enchantNormalArmors) => (
                <li key={enchantNormalArmors}>{enchantNormalArmors}</li>
              ))
            }
          </ul>
          <ul className="listmobile">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              enchantNormalArmor.map((enchantNormalArmors) => (
                <li key={enchantNormalArmors}>{enchantNormalArmors}</li>
              ))
            }
          </ul>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'none', sm: 'block' }
            }}
          >
            Blessed Enchant Scroll Weapon 
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              fontSize: 25,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'block', sm: 'none' },
              textAlign: "center",
              ml: 4,
            }}
          >
            Blessed Enchant Scroll Weapon 
          </Typography>
          <ul className="listdesktop">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              enchantBlessedWeapon.map((enchantBlessedWeapons) => (
                <li key={enchantBlessedWeapons}>{enchantBlessedWeapons}</li>
              ))
            }
          </ul>
          <ul className="listmobile">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              enchantBlessedWeapon.map((enchantBlessedWeapons) => (
                <li key={enchantBlessedWeapons}>{enchantBlessedWeapons}</li>
              ))
            }
          </ul>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'none', sm: 'block' }
            }}
          >
            Blessed Enchant Scroll Armor 
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              fontSize: 25,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'block', sm: 'none' },
              textAlign: "center",
              ml: 4,
            }}
          >
            Blessed Enchant Scroll Armor 
          </Typography>
          <ul className="listdesktop">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              enchantBlessedArmor.map((enchantBlessedArmors) => (
                <li key={enchantBlessedArmors}>{enchantBlessedArmors}</li>
              ))
            }
          </ul>
          <ul className="listmobile">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              enchantBlessedArmor.map((enchantBlessedArmors) => (
                <li key={enchantBlessedArmors}>{enchantBlessedArmors}</li>
              ))
            }
          </ul>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'none', sm: 'block' }

            }}
          >
            Crystal Enchant Scroll 
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              fontSize: 25,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: 'block', sm: 'none' },
              textAlign: "center",
              ml: 4,

            }}
          >
            Crystal Enchant Scroll 
          </Typography>
          <ul className="listdesktop">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              enchantCrystal.map((enchantCrystals) => (
                <li key={enchantCrystals}>{enchantCrystals}</li>
              ))
            }
          </ul>
          <ul className="listmobile">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              enchantCrystal.map((enchantCrystals) => (
                <li key={enchantCrystals}>{enchantCrystals}</li>
              ))
            }
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon fontSize="large" sx={{ color: "white" }} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={styles.eventsInformation}
        >
          <Typography
            align="center"
            sx={{
              width: "100%",
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              textShadow: "3px 3px 3px black",
              display: { xs: "none", sm: "block" },
            }}
          >
            Events Information
          </Typography>
          <Typography
            align="center"
            sx={{
              width: "100%",
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              textShadow: "3px 3px 3px black",
              display: { xs: "block", sm: "none" },
              ml: 7,
            }}
          >
            Events Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "#1e1a23", color: "white" }}>
        <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: "none", sm: "block" },
              
            }}
          >
            Olympiad 
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 25,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: "block", sm: "none" },
              textAlign: "center",
              ml: 4,
            }}
          >
            Olympiad 
          </Typography>
          <ul className="listdesktop">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              olympiads.map((Olympiad) => (
                <li key={Olympiad}>{Olympiad}</li>
              ))
            }
          </ul>
          <ul className="listmobile">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              olympiads.map((Olympiad) => (
                <li key={Olympiad}>{Olympiad}</li>
              ))
            }
          </ul>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 30,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: "none", sm: "block" },
            }}
          >
            Custom Events 
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed Light  sans-serif",
              fontWeight: "700",
              letterSpacing: "4px",
              fontSize: 25,
              color: "#ffd850",
              textDecoration: "none",
              display: { xs: "block", sm: "none" },
              textAlign: "center",
              ml: 4,
            }}
          >
            Custom Events 
          </Typography>
          <ul className="listdesktop">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              customevents.map((customevent) => (
                <li key={customevent}>{customevent}</li>
              ))
            }
          </ul>
          <ul className="listmobile">
            {
              /*  This maps each array item to a div adds
      the style declared above and return it */
              customevents.map((customevent) => (
                <li key={customevent}>{customevent}</li>
              ))
            }
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default Features;
