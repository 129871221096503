import { Paper, Container, Box, Typography, Slide } from "@mui/material";
import CopyrightIcon from '@mui/icons-material/Copyright';

export const Footer = () => {

  return (
    <Paper
      sx={{
        width: '100%',
        position: 'relative',
        bottom: "0",
        width: '100%',
        color: 'white'
      }}
      style={{ background: 'transparent'}}
      component="footer"
      square
      elevation={0}
    >
      <Container maxWidth="false">
          <Box
            sx={{
              justifyContent: "center",
              display: "inline-flex",
              width: "100%",
              my: 5,
            }}
          >
            <Typography
              sx={{
                display: { xs: "none", md: "block" },
                fontFamily: 'Roboto Condensed Light  sans-serif',
                 fontWeight: '700' , 
                 letterSpacing: '4px',
                 fontSize: 20,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              {new Date().getFullYear()} <CopyrightIcon sx={{fontSize: 'inherit' }} /> Omega World Private L2 Server
            </Typography>
            <Typography
              noWrap
              sx={{
                display: { xs: "block", md: "none" },
                fontFamily: 'Roboto Condensed Light  sans-serif',
                 fontWeight: '700' , 
                 letterSpacing: '2px',
                 fontSize: 15,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              {new Date().getFullYear()} <CopyrightIcon sx={{fontSize: 'inherit' }}  /> Omega World Private L2 Server
            </Typography>
          </Box>
      </Container>
    </Paper>
  );
};

export default Footer;
